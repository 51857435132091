import React from 'react';

import Footer from '@nm-namshi-frontend/core/page_components/shared/footer/Footer';
import FullScreenLoader from '@nm-namshi-frontend/core/page_components/shared/loader/FullScreenLoader';
import { CatalogResponse } from '@nm-namshi-frontend/services';
import ErrorView from '@nm-namshi-frontend/core/page_components/shared/error/ErrorView';
import { getSelectedSubDepartmentIdForCatalogUri } from '@nm-namshi-frontend/core/utils/departments';
import { NavDepartmentsResponse } from '@nm-namshi-frontend/core/types';

import HeaderWithDepartmentTabs from '../HeaderWithDepartmentTabs';
import styles from './DefaultLayout.module.scss';

type TProps = {
    catalogResponse?: CatalogResponse | { error: string; status: number };
    navigationData: NavDepartmentsResponse | { error: string; status: number };
    children: React.ReactNode;
    currentPagePath?: string;
    selectedDepartmentIdFromCookie?: string;
    selectedSubDepartmentIdFromCookie?: string;
};

const DefaultLayout = ({
    catalogResponse,
    children,
    currentPagePath,
    navigationData,
    selectedDepartmentIdFromCookie,
    selectedSubDepartmentIdFromCookie,
}: TProps) => {
    if ('error' in navigationData) {
        return <ErrorView error={navigationData.error || 'Navigation Data Error'} />;
    }

    // Get selectedDepartmentId from:
    // 1. selectedTab in catalogResponse
    // 2. Cookie (from previous selection)
    // 3. The first available navigation category department (this is a fallback)
    const selectedDepartmentId =
        (!!catalogResponse && 'selectedTab' in catalogResponse && catalogResponse?.selectedTab) ||
        selectedDepartmentIdFromCookie ||
        navigationData.departments[0].id;

    // Relevant for catalog pages only
    let selectedSubDepartmentIdFromUri = null;
    if (currentPagePath && selectedDepartmentId) {
        selectedSubDepartmentIdFromUri = getSelectedSubDepartmentIdForCatalogUri({
            catalogUri: currentPagePath,
            navigationData,
            selectedDepartmentId,
        });
    }

    // Get selectedSubDepartmentId from:
    // 1. selectedSubDepartmentIdFromUri (determined by catalog url)
    // 2. Cookie (from previous selection)
    // 3. The first available navigation category department's subdepartment (this is a fallback)
    const selectedSubDepartmentId = selectedSubDepartmentIdFromUri || selectedSubDepartmentIdFromCookie;

    if ('departments' in navigationData && navigationData.departments) {
        return (
            <>
                <HeaderWithDepartmentTabs
                    selectedDepartmentId={selectedDepartmentId}
                    selectedSubDepartmentId={selectedSubDepartmentId}
                    navigationData={navigationData}
                    pageType={(catalogResponse as CatalogResponse)?.type}
                />
                <section className={styles.container}>
                    {children}
                    <FullScreenLoader />
                </section>
                <Footer />
            </>
        );
    }

    return <ErrorView error="Navigation Data Error" />;
};

export default DefaultLayout;
