'use client';

import { COOKIE_ID, ERRORVIEW } from '@nm-namshi-frontend/core/constants/uiConstants';
import ErrorView from '@nm-namshi-frontend/core/page_components/shared/error/ErrorView';
import { getCookie } from '@nm-namshi-frontend/core/utils/helper';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { useQuery } from '@tanstack/react-query';

import DefaultLayout from '../../../components/layouts/DefaultLayout';

const NotFoundPage = () => {
    const selectedDepartmentIdFromCookie = getCookie(COOKIE_ID.SELECTED_DEPARTMENT_ID);
    const selectedSubDepartmentIdFromCookie = getCookie(COOKIE_ID.SELECTED_SUBDEPARTMENT_ID);

    const { data: navigationData, isLoading } = useQuery([REACT_QUERY_KEYS.GET_NAVIGATION_CATEGORIES], () =>
        getApiInstance().product.getNavigationCategoriesVersion({
            version: 'v5',
        }),
    );

    if (isLoading || !navigationData) return null;

    return (
        <DefaultLayout
            navigationData={navigationData}
            {...(selectedDepartmentIdFromCookie
                ? {
                      selectedDepartmentIdFromCookie,
                  }
                : {})}
            {...(selectedSubDepartmentIdFromCookie
                ? {
                      selectedSubDepartmentIdFromCookie,
                  }
                : {})}
        >
            <ErrorView type={ERRORVIEW.CATALOG_NOT_FOUND} />
        </DefaultLayout>
    );
};

export default NotFoundPage;
